import request from "@/utils/request";

export function login(params) {
  let data = new FormData();
  data.append("account", params.account);
  data.append("password", params.password);
  return request({
    url: "/tradepc/login/login",
    method: "post",
    data,
  });
}
