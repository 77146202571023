import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    // 登录
    path: "/",
    component: (resolve) => require(["@/components/login"], resolve),
    name: "/login",
  },
  {
    path: "/zhm",
    name: "",
    component: (resolve) => require(["@/layout/layout"], resolve),
    hidden: true,
    children: [
      {
        path: "userList",
        name: "userList",
        component: () => import("@/views/user/userList.vue"),
        meta: {
          hidden: false,
          title: "用户列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "userDetail",
        name: "userDetail",
        component: () => import("@/views/user/userDetail.vue"),
        meta: {
          hidden: false,
          title: "用户详情",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "intermediaryList",
        name: "intermediaryList",
        component: () => import("@/views/user/intermediaryList.vue"),
        meta: {
          hidden: false,
          title: "中介列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "intermediaryDetail",
        name: "intermediaryDetail",
        component: () => import("@/views/user/intermediaryDetail.vue"),
        meta: {
          hidden: false,
          title: "中介详情",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "enterpriseList",
        name: "enterpriseList",
        component: () => import("@/views/user/enterpriseList.vue"),
        meta: {
          hidden: false,
          title: "企业列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "enterpriseDetail",
        name: "enterpriseDetail",
        component: () => import("@/views/user/enterpriseDetail.vue"),
        meta: {
          hidden: false,
          title: "企业详情",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "positionList",
        name: "positionList",
        component: () => import("@/views/position/positionList.vue"),
        meta: {
          hidden: false,
          title: "职位列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "positionDetail",
        name: "positionDetail",
        component: () => import("@/views/position/positionDetail.vue"),
        meta: {
          hidden: false,
          title: "职位详情",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "positionClassify",
        name: "positionClassify",
        component: () => import("@/views/position/positionClassify.vue"),
        meta: {
          hidden: false,
          title: "职位分类",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "advertisingMonitor",
        name: "advertisingMonitor",
        component: () => import("@/views/advertisement/advertisingMonitor.vue"),
        meta: {
          hidden: false,
          title: "在线广告监控",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "advertisingNote",
        name: "advertisingNote",
        component: () => import("@/views/advertisement/advertisingNote.vue"),
        meta: {
          hidden: false,
          title: "广告投放历史",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "financeOverview",
        name: "financeOverview",
        component: () => import("@/views/finance/financeOverview.vue"),
        meta: {
          hidden: false,
          title: "财务概况",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "financeNotes",
        name: "financeNotes",
        component: () => import("@/views/finance/financeNotes.vue"),
        meta: {
          hidden: false,
          title: "财务明细",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "WithdrawalRecords",
        name: "WithdrawalRecords",
        component: () => import("@/views/finance/withdrawalRecords.vue"),
        meta: {
          hidden: false,
          title: "财务明细",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "invoiceApplyRecord",
        name: "invoiceApplyRecord",
        component: () => import("@/views/finance/invoiceApplyRecord.vue"),
        meta: {
          hidden: false,
          title: "发票申请记录",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "policyList",
        name: "policyList",
        component: () => import("@/views/compliance/policyList.vue"),
        meta: {
          hidden: false,
          title: "保单列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "contractList",
        name: "contractList",
        component: () => import("@/views/compliance/contractList.vue"),
        meta: {
          hidden: false,
          title: "合同列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "riskControlList",
        name: "riskControlList",
        component: () => import("@/views/compliance/riskControlList.vue"),
        meta: {
          hidden: false,
          title: "风控列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "riskControlCenter",
        name: "riskControlCenter",
        component: () => import("@/views/compliance/riskControlCenter.vue"),
        meta: {
          hidden: false,
          title: "风控验证中心",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "riskControlHistory",
        name: "riskControlHistory",
        component: () => import("@/views/compliance/riskControlHistory.vue"),
        meta: {
          hidden: false,
          title: "风控历史",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "stuffList",
        name: "stuffList",
        component: () => import("@/views/authority/stuffList.vue"),
        meta: {
          hidden: false,
          title: "员工列表",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "departmentSetting",
        name: "departmentSetting",
        component: () => import("@/views/authority/departmentSetting.vue"),
        meta: {
          hidden: false,
          title: "部门设置",
          icon: "iconfont icon-home",
        },
      },
      {
        path: "authorityList",
        name: "authorityList",
        component: () => import("@/views/authority/authorityList.vue"),
        meta: {
          hidden: false,
          title: "权限列表",
          icon: "iconfont icon-home",
        },
      },
    ],
  },
  {
    path: "/chat/chat",
    name: "chat",
    component: () => import("@/views/chat/chat.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
});

export default router;
