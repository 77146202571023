import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import "./permission"//权限控制
import "./assets/common.css";
import "./assets/icons/iconfont.css";
import moment from "moment";
import * as echarts from "echarts";
import WeixinEmojis from "vue-weixin-emojis";
// import VideoPlayer from "vue-video-player";
// import "vue-video-player/src/custom-theme.css";
// import "video.js/dist/video-js.css";

Vue.config.productionTip = false;

// Vue.use(VideoPlayer);
Vue.use(ElementUI, {
  size: "small",
  zIndex: 3000,
});
Vue.use(WeixinEmojis);

Vue.prototype.$moment = moment;
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
