import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { getIMUserToken } from "@/api/chat.js";

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/','/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  console.log('from.path',from.path)
  console.log('to.path',to.path)
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  console.log("hasToken",hasToken)

  if (hasToken) {
    console.log('to.path',to.path)
    if (to.path === '/') {
      // if is logged in, redirect to the home page
      next({ path: '/zhm' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // // determine whether the user has obtained his permission roles through getInfo
      // // const hasRoles = store.getters.roles && store.getters.roles.length > 0
      // // if (hasRoles) {
      // if (true) {
        const hasRongToken = store.getters.rongToken 
        console.log("hasRongToken",hasRongToken)
        if(!hasRongToken){
          await store.dispatch("ronngClound/getIMUserToken")
        }
        next()
      // } else {
      //   try {
      //     // // get user info
      //     // // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
      //     // const { roles } = await store.dispatch('user/getInfo')

      //     // // generate accessible routes map based on roles
      //     // const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

      //     // // dynamically add accessible routes
      //     // router.addRoutes(accessRoutes)

      //     // hack method to ensure that addRoutes is complete
      //     // set the replace: true, so the navigation will not leave a history record
      //     next({ ...to, replace: true })
      //   } catch (error) {
      //     // remove token and go to login page to re-login
      //     await store.dispatch('user/resetToken')
      //     Message.error(error || 'Has Error')
      //     next(`/login?redirect=${to.path}`)
      //     NProgress.done()
      //   }
      // }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
      if(from.path!="/"&&to.path=='/'){
        // 退出登录，需要刷新当前页
        console.log('退出登录，需要刷新当前页')
        location.reload()
      }
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      console.log('/?redirect=${to.path}')
      // next(`/?redirect=${to.path}`)
      next(`/`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
