import request from "@/utils/request";
//获取IM token
export function getIMUserToken(params) {
  return request({
    url: "/im/usertoken",
    method: "get",
    params,
  });
}
//获取IM好友
export function getFriends(params) {
  return request({
    url: "im/friends",
    method: "get",
    params,
  });
}
