/**
 * call 主要相关逻辑
*/
// IMLib
import { init ,ConversationType} from "@rongcloud/imlib-v4";
// RTCLib
import { installer as rtcInstaller,RCRTCClient, RCTrack, RCFrameRate, RCResolution } from "@rongcloud/plugin-rtc";
// CallLib
import { installer as callInstaller, RCCallMediaType, RCCallErrorCode } from "@rongcloud/plugin-call";
//RCEngine
import { LogLevel } from "@rongcloud/engine"

import { RCToast,RCDom } from "./utils"
import store from "@/store"

// IM 实例
let imClient
// CallSession 实例
let callSession;
// Call 呼叫类型
let callType = ConversationType.PRIVATE;
// Call 媒体类型
let mediaType = RCCallMediaType.AUDIO;

// RTC 实例
let rtcClient;
// CallLib 实例
let callClient;

/**
 * 一、IM 初始化
 */
export const initClient = () => {
    // IM 实例
    imClient =init({
        appkey: "lmxuhwaglurdd",
        navigators: undefined,
        logLevel: 1
    });
}

/**
 * 二、RTC 初始化
 * 在 IM 初始化后进行初始化 （具体位置：im.js）
 */
 export const initRTC = () => {
    rtcClient = imClient.install(rtcInstaller, {
    timeout: 30 * 1000,
    logLevel: LogLevel.NONE
    });
}
    
/**
 * 三、CallLib 初始化
 * 在 IM 初始化后进行初始化 （具体位置：im.js）
 */ 
export const initCall = () => {
    callClient = imClient.install(callInstaller, {
    rtcClient: rtcClient,
    onSession: (session) => {
        store.dispatch('ronngClound/openCall')
        callSession = session;
        mediaType = session.getMediaType();
        RCToast(`onSession`);
        registerCallSessionEvent(callSession);
        RCToast(`收到 ${session.getCallerId()} 的通话邀请`);
        store.dispatch('ronngClound/callIncomming',true)
    },
    onSessionClose: (session, summary) => {
        RCToast('通话已结束');
        store.dispatch('ronngClound/callEnd',true)
        store.dispatch("ronngClound/closeCall");
        removeVideoEl();
    }
    });
}
/**
 * 四、IM 监听
 */
export const watchIM = () => {
    imClient.watch({
        // 监听消息通知
        message(event) {
          // 新接收到的消息内容
          const message = event.message;
          console.log("新接收到的消息内容", message);
          store.dispatch('ronngClound/receivedMessage',message)
        },
        // 监听 IM 连接状态变化
        status(event) {
            const status={
              0:'连接成功',
              1:'连接中',
              2:'正常断开连接',
              3:'网络不可用',
              4:'连接关闭',
              6:'用户账户在其他设备登录，本机会被踢掉线',
              7:'websocket 连接失败',
              8:'websocket 报错',
              9:'用户被封禁',
              12:'域名错误',
              13:'服务器主动断开',
              14:'重定向',
              20:'appkey 不正确',
              1101:'互踢次数过多（`count > 5`），此时可能出现：在其它他设备登陆有 reconnect 逻辑',
              201:'开始请求导航',
              202:'请求导航结束',
              203:'请求导航失败',
              204:'请求导航超时',
            }
            console.log("IM 连接状态:", `status:${event.status},${status[event.status]}`);
        },
        // 监听离线消息拉取完成
        pullFinished() {
            console.log("拉取离线消息完成");
        },
        // 本端发送的消息被屏蔽时触发
        messageBlocked(event) {
            const messageId = event.blockedMessageUId;
            const conversationType = event.conversationType;
            const targetId = event.targetId;
            const blockType = event.blockType;
        },
        // 该回调不会给当前操作设备回调，只会给其他的多端设备回调
        // 当用户在其它端添加移除更新标签时会触发此监听器，用于多端之间的信息同步
        tag() {
            console.log("标签发生变化");
        },
    });
}
/**
 * 五、连接融云
 * @param {*} token 
 * @returns 
 */
 export const connectIM = (token) => {
    if (!token) {
      RCToast('请输入 Token');
      return;
    }
  
    RCToast('正在链接 IM ... ☕️');
    imClient.connect({ token }).then((user) => {
      console.log(`连接用户id:${user.id}`);
      localStorage.setItem("userId", user.id);
      store.dispatch("ronngClound/connect",user.id)
      RCToast(`用户 ${user.id} IM 链接成功 ✌🏻`);
    }).catch((error) => {
      console.log(error)
      localStorage.removeItem("userId")
      store.dispatch("ronngClound/connect",'')
      RCToast('IM 链接失败，请检查网络后再试')
    });
}

export const disconnectIM = () => {
    return new Promise((resolve,reject)=>{
        imClient
        .disconnect()
        .then(() => {
          console.log("断开链接成功");
          localStorage.removeItem("userId");
          store.dispatch("ronngClound/disconnect")
          resolve()
        })
        .catch((error) => {
          console.log('断开链接失败',error);
          RCToast('IM 断开链接失败，请检查网络后再试')
          reject(error)
        });
    })
}
export const sendMessage = ({targetId,type,messageType,content}) =>{
    // 获取指定会话的抽象实例，对于会话的操作基于此实例完成
    const conversation = imClient.Conversation.get({
      // targetId
      targetId,
      // 会话类型：RongIMLib.CONVERSATION_TYPE.PRIVATE | RongIMLib.CONVERSATION_TYPE.GROUP
      type: type,
    });
    return new Promise((resolve,reject)=>{
        // 向会话内发消息
        conversation
            .send({
                // 消息类型，其中 RongIMLib.MESSAGE_TYPE 为 IMLib 内部的内置消息类型常量定义
                messageType, // 'RC:TxtMsg'
                // 消息内容
                content: {
                    content, // 文本内容
                },
            })
            .then( (message) => {
                console.log("发送文字消息成功", message);
                resolve(message)
            })
            .catch((error) => {
                console.log("发送文字消息失败", error);
                reject(error)
            });
    })
}
/**
 * 通话类型监听
 */
export const callTypeChange = (callType) => {
  if (callType === ConversationType.GROUP) {
    RCDom.showBlock('paramGroupId');
    RCDom.showBlock('paramInvitedIds');
    RCDom.hide('paramPrivate');
  } else {
    RCDom.hide('paramGroupId');
    RCDom.hide('paramInvitedIds');
    RCDom.showBlock('paramPrivate');
  }
}

/**
 * 媒体类型监听
 */
 export const callMediaTypeChange = () => {
  const mediaTypeDom = RCDom.get('callMediaType');
  mediaType = Number(mediaTypeDom.value);
}

/**
 * CallSession 事件 
 */
 export const getCallSessionEvent = () => {
  return {
    onRinging: (sender) => {
      RCToast(`收到 ${sender.userId} 振铃`);
    },
    onAccept: (sender) => {
      RCToast(`${sender.userId} 已接听`);
    },
    onHungup: (sender) => {
      RCToast(`${sender.userId} 已挂断`);
      // 群组中移除相应节点
      const videoViewDom = RCDom.get('videoView');
      const videoDom = RCDom.get(`video-${sender.userId}`);
      videoDom && videoViewDom.removeChild(videoDom);
    },
    onTrackReady: (track) => {
      RCToast(`onTrackReady:${track}`);
      appendVideoEl(track)
      if (!track.isLocalTrack()) {
        RCToast('通话已建立')
        store.dispatch('ronngClound/callInTheCall',true)
      }
    },
    onMemberModify: (sender) => {},
    onMediaModify: (sender) => {},
    onAudioMuteChange: (muteUser) => {},
    onVideoMuteChange: (muteUser) => {}
  }
}

/**
 * callSession 事件注册
 */
 export const registerCallSessionEvent = (session) => {
  RCToast(`registerCallSessionEvent`);
  const events = getCallSessionEvent()
  session.registerSessionListener(events)
}

/**
 * callSession 呼叫
 */
 export const call = ({callType,targetId,mediaType,constraints}) => {
  store.dispatch("ronngClound/openCall");
  RCToast(`call`);
  const events = getCallSessionEvent()
  const isPrivateCall = callType === ConversationType.PRIVATE
  const params = {
    targetId,
    mediaType,
    listener: events,
    constraints
  }
  if (isPrivateCall) {
    privateCall(params)
  } else {
    groupCall(params)
  }
}

/**
 * 单呼
*/
export const privateCall = (params) => {
  RCToast(`privateCall`);
  callClient.call(params).then(({ code, session }) => {
    if (code === RCCallErrorCode.SUCCESS) {
      RCToast(`privateCall`);
      registerCallSessionEvent(session)
      callSession = session
      store.dispatch('ronngClound/callOutgoing',true)
    } else {
      RCToast(`呼叫失败，错误原因：${code}`)
    }
  })
}

/**
 * 群呼
 */
 export const groupCall = (params) => {
  params.userIds = (RCDom.get('userIds').value || []).split(',')
  callClient.callInGroup(params).then(({ code, session }) => {
    if (code === RCCallErrorCode.SUCCESS) {
      registerCallSessionEvent(session)
      callSession = session
      store.dispatch('ronngClound/callOutgoing',true)
    } else {
      const reason = code === RCCallErrorCode.NOT_IN_GROUP ? '当前用户未加入群组' : code;
      RCToast(`呼叫失败，错误原因：${reason}`);
      removeVideoEl();
    }
  })
}

/**
 * 接听当前 callSession
 */
 export const accept = () => {
     return new Promise((resolve,reject)=>{
        callSession.accept().then(({ code }) => {
            if (code === RCCallErrorCode.SUCCESS) {
              RCToast('接听成功')
              resolve()
            } else {
              RCToast(`接听失败，错误原因：${code}`)
              reject()
            }
        })
     })
  
}

/**
 * 挂断当前 callSession
 */
 export const hungup = () => {
     return new Promise((resolve,reject)=>{
        callSession.hungup().then(({ code }) => {
            if (code === RCCallErrorCode.SUCCESS) {
              RCToast('挂断成功')
              resolve()
            } else {
              RCToast(`挂断失败，错误原因：${code}`)
              reject()
            }
        })
     })
  
}

/**
 * video 视图渲染
 */
 export const appendVideoEl = (track) => {
  const container = RCDom.get('videoView');
  if (track.isAudioTrack()) {
    const uid = track.getUserId();
    const node = document.createElement('div');
    node.setAttribute('id', `video-${uid}`);
    const videoTpl = `<span class="video-user-id">ID: ${uid}</span>
      <span class="video-media-type">${mediaType === 1 ? '音频' : ''}</span>
      <video id="${uid}"></video>`;
    node.innerHTML = videoTpl;
    node.classList = 'video-item';
    container.appendChild(node);
    track.play();
  } else {
    const videoEl = RCDom.get(track.getUserId());
    track.play(videoEl)
  }
}

/**
 * 通话结束后，清除所有 video 标签
 */
 export const removeVideoEl = () => {
  RCDom.get('videoView').innerHTML = '';
};