import Vue from "vue";
import { Notification } from 'element-ui';
import { getIMUserToken } from "@/api/chat.js";
const state = {
    dialogVisible:false,
    rongToken:'',
    userId:'',
    message:'',
    incomming:false,
    inTheCall:false,
    outgoing:false,
    end:false,
    canHungup:true,
    canAccept:true,
}

const mutations = {
  setMessage(state,message){
    state.message=message
  },
  setDialogVisible(state,dialogVisible){
    state.dialogVisible=dialogVisible
  },
  setRonngToken(state,rongToken){
    state.rongToken=rongToken
  },
  setUserId(state,userId){
    state.userId=userId
  },
  setIncomming(state,incomming){
    state.incomming=incomming
  },
  setInTheCall(state,inTheCall){
    state.inTheCall=inTheCall
  },
  setOutgoing(state,outgoing){
    state.outgoing=outgoing
  },
  setEnd(state,end){
    state.end=end
  },
  setHungup(state,canHungup){
    state.canHungup=canHungup
  },
  setAccept(state,canAccept){
    state.canAccept=canAccept
  }
}

const actions = {
    receivedMessage({commit,},message){
      console.log('actions receivedMessag')
      Notification.info({title:"收到新消息",message:message.content.content,position:'bottom-right'})
      commit('setMessage',message)
    },
    openCall({commit}){
      commit('setDialogVisible',true)
    },
    closeCall({commit}){
      commit('setDialogVisible',false)
    },
    async getIMUserToken({commit,}){
      const data = await getIMUserToken();
        if (data.code == 200) {
          const rongToken = data.data.token;
          commit("setRonngToken",rongToken)
        }
    },
    connect({commit},userId){
      commit('setUserId',userId)
      commit('setHungup',true)
      commit('setAccept',true)
    },
    /**
     * 断开当前用户的连接
     * @param {commit}  
     */
     disconnect({commit}){
      commit('setUserId','')
     },
     callIncomming({commit},incomming){
      commit('setIncomming',incomming)
      commit('setHungup',true)
      commit('setAccept',true)
     },
     callInTheCall({commit},inTheCall){
      commit('setInTheCall',inTheCall)
      commit('setHungup',true)
      commit('setAccept',false)
     },
     callOutgoing({commit},outgoing){
      commit('setOutgoing',outgoing)
      commit('setHungup',true)
      // commit('setAccept',true)
     },
     callEnd({commit},end){
      commit('setEnd',end)
      commit('setHungup',false)
      commit('setAccept',false)
     }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
