import axios from "axios";
import Vue from "vue";
import router from "@/router/index.js";

//使用自定义配置新建一个实例
const instance = axios.create({
  // 基础的请求地址
  baseURL: "https://adminapi.zhaohuome.com/",
  // 设置超时时间 30s
  timeout: 30000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

// 添加超时后的处理（axios中需要你根据error信息来进行判断）
axios().catch((error) => {
  const { message } = error;
  if (error.code === "ECONNABORTED" && message.indexOf("timeout") > -1) {
    // 超时处理，可以直接弹出错误或者重新发起一次请求
    alert("请求超时！请检查网络问题");
    //  let newHttp= new Promise(function (resolve){
    //      resolve()
    //  })
    //  newHttp实例执行完成后会再次执行
    //  返回一个promise实例，同时重新发起请求，config请求配置，包扩请求头和请求参数
    //  return newHttp.then(function (){
    //      return  axios.create({baseURL: 'https://some-domain.com/api/',timeout: 5000});
    //  })
  }
  // 若不是超时,则返回未错误信息
  return Promise.reject(error);
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    if (config.url != "/tradepc/login/login") {
      //如果不是登录接口 就需要加上token
      if (localStorage.getItem("token")) {
        config.headers["admin-auth-token"] = localStorage.getItem("token");
      }
      // console.log(config)
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    if (response.status === 200) {
      const data = response.data;
      if (data.code == 4001) {
        console.log(data.message);
        Vue.prototype.$message({
          message: data.message,
          type: "error",
        });
      } else if (data.code == 4002) {
        Vue.prototype.$message({
          message: data.message,
          type: "error",
        });
        // 清除token
        localStorage.removeItem("account");
        localStorage.removeItem("avatar");
        localStorage.removeItem("lastlogin");
        localStorage.removeItem("realname");
        localStorage.removeItem("token");
        // 跳转登录
        setTimeout(() => {
          router.replace({
            name: "/",
            query: { redirect: router.currentRoute.fullPath }, //登录后再跳回此页面时要做的配置
          });
        }, 1000);
      }
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    if (404 == error.response.status) {
      Vue.prototype.$message({
        message: error.response.data.message,
        type: "error",
      });
    } else {
      if (error.response.status >= 500) {
        Vue.prototype.$message({
          message: "服务器开小差了，请稍后再试！",
          type: "error",
        });
      } else {
        Vue.prototype.$message({
          message: "服务器开小差了，请稍后再试！",
          type: "error",
        });
        return Promise.reject(error);
      }
    }
  }
);

export default instance;
