<template>
  <div id="app">
    <div class="toast-wrap">
      <span class="toast-msg"></span>
    </div>
    <router-view />
    <el-dialog
      title="音视频通话"
      :visible.sync="dialogVisible"
      @close="close"
      width="80vw"
    >
      <div class="rong-container">
        <div id="rongUser" class="rong-user">
          <span>用户 ID：</span>
          <span id="rongUserId">{{ userId }}</span>
        </div>

        <div class="rong-im" id="rongIM" style="display: none">
          <p>请先进行 IM 连接</p>
          <!--appkey-->
          <div class="im-item">
            <label>App Key</label>
            <input type="text" id="appkey" placeholder="请输入 App Key" />
            <p>
              必填；可通过
              <a target="_blank" href="https://developer.rongcloud.cn/"
                >[开发者后台] -> [服务管理] -> [基本信息]</a
              >
              获取
            </p>
          </div>
          <!--token-->
          <div class="im-item">
            <label>Token</label>
            <input type="text" id="token" placeholder="请输入 Token" />
            <p>
              必填；可通过
              <a target="_blank" href="https://developer.rongcloud.cn/"
                >[开发者后台] -> [服务管理] -> [API 调用] -> [用户服务] -> [获取
                token]</a
              >
              获取
            </p>
          </div>
          <!--navi-->
          <div class="im-item">
            <label>Navi</label>
            <input type="text" id="navi" placeholder="请输入 Navi 地址" />
            <p>非必填；私有云环境必填</p>
          </div>
          <!--mediaServer-->
          <div class="im-item">
            <label>MediaServer</label>
            <input
              type="text"
              id="mediaServer"
              placeholder="请输入 MediaServer 地址"
            />
            <p>非必填；音视频服务地址</p>
          </div>
          <!--连接-->
          <div class="im-item">
            <button onclick="connectIM()">连接</button>
          </div>
        </div>

        <div class="rong-call" id="rongCall" style="display: block">
          <!--通话视图展示-->
          <div id="videoView" class="video-view"></div>
        </div>
      </div>
      <span slot="footer">
        <!-- <el-button v-if="end" @click="handleCall">呼叫</el-button> -->
        <el-button v-if="canHungup" @click="handleHungup" type="danger"
          >挂断</el-button
        >
        <el-button v-if="canAccept" type="success" @click="handleAccept"
          >接听</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const RCDom = {
  get: (id) => {
    return document.getElementById(id);
  },
  show: (id) => {
    var rongIMDom = document.getElementById(id);
    rongIMDom.setAttribute("style", "display:inline-block;");
  },
  showBlock: (id) => {
    var rongIMDom = document.getElementById(id);
    rongIMDom.setAttribute("style", "display:block;");
  },
  hide: (id) => {
    var rongIMDom = document.getElementById(id);
    rongIMDom.setAttribute("style", "display:none;");
  },
};
import { mapState } from "vuex";
import {
  initClient,
  initRTC,
  initCall,
  watchIM,
  connectIM,
} from "@/rongcloud/call";
import { RCCallErrorCode } from "@rongcloud/plugin-call";
import { accept, hungup } from "@/rongcloud/call";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      rongToken: (state) => state.ronngClound.rongToken,
      userId: (state) => state.ronngClound.userId,
      dialogVisible: (state) => state.ronngClound.dialogVisible,
      incomming: (state) => state.ronngClound.incomming,
      inTheCall: (state) => state.ronngClound.inTheCall,
      outgoing: (state) => state.ronngClound.outgoing,
      end: (state) => state.ronngClound.end,
      canHungup: (state) => state.ronngClound.canHungup,
      canAccept: (state) => state.ronngClound.canAccept,
    }),
  },
  watch: {
    rongToken(newValue, oldValue) {
      if (newValue) {
        console.log("rongToken", newValue);
        initClient();
        initRTC();
        initCall();
        watchIM();
        connectIM(newValue);
      }
    },
    userId(newValue, oldValue) {
      if (newValue) {
        console.log("userId");
      }
    },
    incomming(newValue, oldValue) {
      if (newValue) {
        console.log("incomming");
      }
    },
    inTheCall(newValue, oldValue) {
      if (newValue) {
        console.log("inTheCall");
      }
    },
    outgoing(newValue, oldValue) {
      if (newValue) {
        console.log("outgoing");
      }
    },
    end(newValue, oldValue) {
      if (newValue) {
        console.log("end");
      }
    },
  },
  created() {
    console.log("App create");
  },
  destroyed() {},
  methods: {
    handleCall() {},
    handleAccept() {
      accept()
        .then(() => {})
        .catch((err) => {});
    },
    handleHungup() {
      hungup()
        .then(() => {})
        .catch((err) => {});
    },
    sure() {},
    close() {},
  },
};
</script>

<style>
@import "~@/assets/style.css";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.video-view {
  height: 60vh;
  background-color: black;
  position: relative;
}
.him {
  position: relative;
  /* background-color: burlywood; */
  height: 100%;
}
.me {
  position: relative;
  /* background-color: springgreen; */
  width: 280px;
  height: 210px;
  position: absolute;
  top: 10px;
  right: 10px;
}
video {
  width: 100%;
  height: 100%;
  /* background-color: purple; */
}
</style>
